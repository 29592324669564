(function(){
  let accordionBtns = document.querySelectorAll(".js-category-item-trigger");
  let accordionBtnOpenClass = 'is-active';
  let accordionBtnTransitionendClass = 'is-ended';
  let categoryItem = document.querySelectorAll(".category-item");
  const term = 300; 
  let timer = 0; 

  let inertFn = function() {
    if (window.matchMedia('(max-width: 759.98px)').matches) {
      categoryItem.forEach((element)=>{
        if(element.classList.contains("is-active")){
          element.getElementsByClassName("category-list-wp")[0].inert = false;
        }else{
          element.getElementsByClassName("category-list-wp")[0].inert = true;
        }
      });
      accordionBtns.forEach((element)=>{
        element.role="button";
        element.tabIndex = "0";
      });
    } else {
      categoryItem.forEach((element)=>{
        element.getElementsByClassName("category-list-wp")[0].inert = false;
      });
      accordionBtns.forEach((element)=>{
        element.role="";
        element.tabIndex = "-1";
      });
    }
  }
  let clickFn = function(btn,target_inert) {
    if( btn.classList.contains(accordionBtnOpenClass) ){
      btn.classList.remove(accordionBtnOpenClass);
      btn.classList.remove(accordionBtnTransitionendClass);
      target_inert.inert = true;
    }else{
      btn.classList.add(accordionBtnOpenClass);
      target_inert.inert = false;
    }
    let transition = event.currentTarget.nextElementSibling
    transition.addEventListener("transitionend", () => {
      if(btn.classList.contains("is-active") == true){
        btn.classList.add(accordionBtnTransitionendClass);
      }
    });
  }
  let langFn = function() {
    let browserLang = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || window.navigator.userLanguage || window.navigator.browserLanguage;
    judgeLang(browserLang)
  }
  let judgeLang = function(lang) {
    if(lang.startsWith('en') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">PRIVACY NOTICE:</span> Each country and regional website of Nintendo is governed by its own privacy policy so please be sure to carefully review and familiarize yourself with the privacy policy of each Nintendo website you visit. You can find your applicable privacy policy <a href="privacy-policy/">here</a>.';
    }
    if(lang.startsWith('fr') == true){
      if(lang == "fr-CA"){
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">POLITIQUE DE CONFIDENTIALITÉ :</span> Le site web Nintendo de chaque pays et région est gouverné par sa propre politique de confidentialité, assurez-vous de soigneusement revoir et vous familiariser avec la politique de confidentialité de chaque site web Nintendo que vous visitez. Vous pouvez consulter la politique de confidentialité vous concernant <a href="privacy-policy/">ici</a>.';
      }else{
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">POLITIQUE DE CONFIDENTIALITÉ :</span> le site Internet de Nintendo de chaque pays et de chaque région est gouverné par sa propre politique de confidentialité. Assurez-vous de lire attentivement et de vous familiariser avec la politique de confidentialité de chaque site Internet de Nintendo que vous visitez. Vous pouvez consulter la politique de confidentialité vous concernant <a href="privacy-policy/">ici</a>.';
      }
    }
    if(lang.startsWith('es') == true){
      if(lang == "es-AR" || lang == "es-CO" || lang == "es-CR"|| lang == "es-HN"|| lang == "es-419"|| lang == "es-MX"|| lang == "es-PE"|| lang == "es-US"|| lang == "es-UY"|| lang == "es-VE"){
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">AVISO DE PRIVACIDAD:</span> Cada sitio web de Nintendo para los distintos países o regiones se rige por su propia política de privacidad. Asegúrate de leer y familiarizarte con la política de privacidad de cada sitio web de Nintendo que visites. Puedes encontrar la política de privacidad que aplica a tu región actual <a href="privacy-policy/">aquí</a>.';
      }else{
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">AVISO DE PRIVACIDAD:</span> El sitio web de Nintendo de cada país o región se rige por su propia política de privacidad. Lee detenidamente la política de privacidad de cada sitio web de Nintendo que visites y familiarízate con ella. <a href="privacy-policy/">Aquí</a> puedes encontrar la política de privacidad correspondiente a tu región actual.';
      }
    }
    if(lang.startsWith('pt') == true){
      if(lang == "pt-BR"){
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">POLÍTICA DE PRIVACIDADE:</span> A página da Nintendo de cada país e região é regida por sua própria política de privacidade, portanto, leia atentamente e familiarize-se com a política de privacidade de cada página da Nintendo que você visitar. Você pode encontrar a política de privacidade que lhe é aplicável <a href="privacy-policy/">aqui</a>.';
      }else{
        document.getElementById("text-privacy").innerHTML = '<span class="bolder">POLÍTICA DE PRIVACIDADE:</span> A página da Nintendo de cada país e região é regida pela sua própria política de privacidade, portanto, leia atentamente e familiarize-se com a política de privacidade de cada página da Nintendo que visitar. Pode encontrar a política de privacidade que lhe é aplicável <a href="privacy-policy/">aqui</a>.';
      }
    }
    if(lang.startsWith('ja') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">プライバシーポリシーについて：</span>任天堂の各国および各地域のウェブサイトは、それぞれ独自のプライバシーポリシーが適用されますので、訪問する各サイトのプライバシーポリシーを<a href="privacy-policy/">こちら</a>からご確認ください。また、本ページにも訪問先サイトのプライバシーポリシーが適用されます。';
    }
    if(lang.startsWith('it') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">NOTA SULLA PRIVACY:</span> Il sito Nintendo di ogni paese o regione segue la propria policy sulla privacy, quindi assicurati di prendere in esame e di acquisire familiarità con la policy sulla privacy di ogni sito Nintendo che visiti. <a href="privacy-policy/">Qui</a> puoi trovare la policy sulla privacy che si applica a te.';
    }
    if(lang.startsWith('de') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">DATENSCHUTZHINWEIS:</span> Jede Landes- und regionale Website von Nintendo unterliegt ihrer eigenen Datenschutzrichtlinie. Stelle darum bitte sicher, dass du die betreffende Datenschutzrichtlinie jeder Nintendo-Website, die du besuchst, sorgfältig gelesen und dich mit dem Inhalt vertraut gemacht hast. Die für dich maßgebliche Datenschutzrichtlinie findest du <a href="privacy-policy/">hier</a>.';
    }
    if(lang.startsWith('nl') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">OPMERKING OVER PRIVACY:</span> op de Nintendo-website van elk land of elke regio is een individueel privacybeleid van toepassing, dus neem voor elke Nintendo-website die je bezoekt het betreffende privacybeleid zorgvuldig door. Het privacybeleid dat voor jou van toepassing is, vind je <a href="privacy-policy/">hier</a>.';
    }
    if(lang.startsWith('ko') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">개인정보 처리 방침에 대하여 :</span> 닌텐도의 각 국가 및 지역의 웹사이트에는 각기 다른 개인정보 처리 방침이 적용되므로, 방문하는 각 사이트의 개인정보 처리 방침을 <a href="privacy-policy/">이곳</a>에서 확인하시기 바랍니다. 또한, 이 페이지에도 방문한 사이트의 개인정보 처리 방침이 적용됩니다.';
    }
    if(lang.startsWith('zh-tw') == true){
      document.getElementById("text-privacy").innerHTML = '<span class="bolder">有關隱私權政策：</span>任天堂各個國家及各個地區的網站，各自就其個別的隱私權政策而適用，請<a href="privacy-policy/">按此</a>確認您欲瀏覽的各個網站之隱私權政策。此外，您所瀏覽的網站之隱私權政策於同樣適用於本頁面。';
    }
  }
  langFn();
  inertFn();
  window.addEventListener("resize", function(){
    clearTimeout(timer);
    timer = setTimeout(function(){
      inertFn();
    }, term);
  });
  accordionBtns.forEach((element)=>{
    element.addEventListener('keydown',(event)=>{
      if (event.key == "Enter") {
        let btn = event.currentTarget.parentNode;
        let target_inert = event.currentTarget.parentNode.getElementsByClassName("category-list-wp")[0];
        clickFn(btn,target_inert);
      }
    });
    element.addEventListener('click',(event)=>{
      let btn = event.currentTarget.parentNode;
      let target_inert = event.currentTarget.parentNode.getElementsByClassName("category-list-wp")[0];
      clickFn(btn,target_inert);
    });
  });
})();